import React from "react";

import "./Navbar.less";
import SBCALogo from "./logos/SBCALogo";
import Icons from '../assets/images/icons/ui';

import { NavLink } from 'react-router-dom';
// import classNames from "classnames";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faSignInAlt,
//   faSignOutAlt,
// } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { connect } from "react-redux";

const Navbar = ({ toggleDrawer, ...props }) => {
  const user = useSelector((state) => state.user);
  const drawerToggle = (
    <div className="nav-drawer__toggle" onClick={toggleDrawer}><img src={Icons.menuToggle.svg} alt="Drawer Toggle" /></div>
  )

  const loginLink = (
    (user && !user.isLoggedIn) ?
      (
        <NavLink to="/user/new-login"
          className="Navbar__nav-link" activeClassName="nav-link__active">
          <div className="Navbar__nav-wrapper">
            Login
          </div>
          {/* <FontAwesomeIcon icon={faSignInAlt} /> */}
        </NavLink>
      ) : (
        <NavLink to="/user/logout"
          className="Navbar__nav-link" activeClassName="nav-link__active">
          <div className="Navbar__nav-wrapper">
            Log Out
            {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
          </div>
        </NavLink>
      )
  )

  return (
    <nav className="Navbar">
      <div>
        <SBCALogo />
        {/* <NavLink to="/my-packages" isActive={(match, location) => {
          if (!match) {
            return false;
          }
          // Other logic for various routes for which to keep the header pink
          return true;
        }}
          className={
            classNames(
              'Navbar__nav-link',
              (user && !user.isLoggedIn) && 'hidden'
            )
          }
          activeClassName="nav-link__active">
          <div className="Navbar__nav-wrapper">
            My Packages
          </div>
        </NavLink> */}
        {/* <NavLink to="/events/today"
        className="Navbar__nav-link" activeClassName="nav-link__active">
          <div className="Navbar__nav-wrapper">
            Events
          </div>
        </NavLink> */}
      </div>
      <div className="right">
        {drawerToggle}
        {loginLink}
      </div>
    </nav>
  );
}

export default connect((state) => ({
  user: state.user
}))(Navbar);
import React from 'react';
import App from './App';
import * as serviceWorker from './services/serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import { configureAppStore } from "./store/configureStore";

const root = document.getElementById('root');
const root1 = createRoot(root);

const store = configureAppStore();

root1.render(
  <BrowserRouter>
    <ScrollToTop />
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

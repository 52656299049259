import React from 'react';
import { connect } from "react-redux";
import { Drawer } from "antd";
import styled from 'styled-components';
import { SlideNavHeader, SlideNavBody, NavLinkItem, SlideNavSection } from './components'
import { mainLinks, otherLinks } from './slideout-link-list';
import { useWindowDimensions } from '../../utils/window'
import { FlexSpacer } from '../Util';

const StyledDrawer = styled(Drawer)`
  color: #fff;

  .ant-drawer-header{
    display: none;
  }
  .ant-drawer-body {
    padding: 14px;
    display: flex;
    flex-direction: column;
  }
`;

const drawerStyle = {
  "backgroundColor": "#001529"
}

const getRenderLinks = (linkArray, icons = true) => linkArray.map((link) => <NavLinkItem key={link.key} icons={icons} linkItem={link} />)

const DrawerNav = ({ visible, toggleDrawer, user, className, ...props }) => {
  const { width: currentWindowWidth } = useWindowDimensions();
  const drawerSide = currentWindowWidth > 480 ? 'right' : 'left';

  return (
    <StyledDrawer
      className={className}
      placement={drawerSide}
      closable={false}
      onClose={toggleDrawer}
      open={visible}
      width={308}
      getContainer={false}
      drawerStyle={drawerStyle}
    >
      <SlideNavHeader user={user} />
      <SlideNavBody>
        <SlideNavSection header="Manage">
          {getRenderLinks(mainLinks)}
        </SlideNavSection>
        {/* <SlideNavSection header="administration">
          {getRenderLinks(adminLinksList)}
        </SlideNavSection> */}
        <FlexSpacer />
        <SlideNavSection header="other">
          {getRenderLinks(otherLinks, false)}
        </SlideNavSection>
      </SlideNavBody>
    </StyledDrawer>
  );
};

DrawerNav.propTypes = {
};

const mapStateToProps = state => {
  return {
    user: state.user
  }
}
export default connect(mapStateToProps)(DrawerNav)

/* --------------------------------- IMPORTS -------------------------------- */

import React, { useEffect, useState } from "react";
import "./package.template.less";
import { getSinglePackage } from "../../store/slices/packages";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { NavLink } from "react-router-dom";
import { Layout, message } from "antd";
import axios from "axios";
import { DefaultLayout } from "../../layout/DefaultLayout";
const { Content } = Layout;

/* -------------------------------------------------------------------------- */

/**
 * Package Template
 * @export
 * @class PackageTemplate
 * @extends {Component}
 */
function PackageTemplate(props) {
  const userid = props.location.state

  const [pdfData, setPdfData] = useState()
  const [data, setData] = useState()
  // const [url, setUrl] = useState()
  const dispatch = useDispatch()
  const user = useSelector(state => state?.user)
  const current_package = useSelector(state => state?.packages?.data?.current_package)
  const subscriber = current_package?.field_subscriber_reference?.label
  const subscriber_url = subscriber !== "" ? subscriber?.toLowerCase().replace(/ /g, "-") : "qualtim"
  const package_url = `/package/${subscriber_url}/${current_package.nid}`
  const pageUrl = window.location.pathname
  const nid_value = userid?.nid
  useEffect(() => {
    (async () => {
      const nid = nid_value ? nid_value : pageUrl.split('/').pop()
      const resultAction = await dispatch(getSinglePackage(nid));
      const originalPromiseResult = unwrapResult(resultAction);
      const pdf_ref = originalPromiseResult?.[0]?.field_pdf_reference
      const pdf_data = pdf_ref !== undefined && Object.values(pdf_ref).map((key) => key.field_pdf);

      setPdfData(pdf_data);

      const subscriber_name = (originalPromiseResult?.[0]?.field_subscriber_reference?.label) === undefined ? "" : originalPromiseResult?.[0]?.field_subscriber_reference?.label
      const NotesString = 'Publication printed or distributed by ' + subscriber_name + ' with permission of the Structural Building Components Association (SBCA) and the Truss Plate Institute (TPI).'
      const customerNamestring = (originalPromiseResult?.[0]?.field_customer_name?.[0]?.value) === undefined ? "" : originalPromiseResult?.[0]?.field_customer_name?.[0]?.value
      const logo = (originalPromiseResult?.[0]?.field_subscriber_reference?.logo) === undefined ? "" : originalPromiseResult?.[0]?.field_subscriber_reference?.logo
      const address = originalPromiseResult?.[0]?.field_address
      const fullAddress = address?.[0]?.address_line1 + ', ' + address?.[0]?.locality + ', ' + address?.[0]?.administrative_area + ' ' + address?.[0]?.postal_code
      const sample = (originalPromiseResult?.[0]?.field_location_reference?.testing) === undefined ? "" : originalPromiseResult?.[0]?.field_location_reference?.testing
      const data = {
        nid: originalPromiseResult?.[0]?.nid,
        jobNumber: originalPromiseResult?.[0]?.field_job_number?.[0]?.value,
        ExpirationDate: "2022-10-06",
        Notes: NotesString,
        logo: logo,
        company: subscriber_name,
        PackageDetails: pdf_data,
        Address: fullAddress,
        sample: sample,
        customerName: customerNamestring,
      };
      setData(data)

    })();
  }, [nid_value, pageUrl, userid, dispatch])

  const handleClick = () => {
    message.info("Please wait for a few minutes while we are generating your PDF");
    //  let xy=window.open()

    var xy = window.open('/loading')
    console.log(xy);

    pdfData ? axios.post(process.env.REACT_APP_API_URLQ, data).then((res) => xy.location = res.data.path) : alert("Pdf is not there")

  }

  return (
    <DefaultLayout
      className="package-view"
      style={{ minHeight: "100vh" }}
    >
      <Layout
        style={{ flex: 1 }}
        className="main-content"
      >
        <Content style={{ padding: "0px 30px" }}>
          <div className="main-wrapper">

            {Object.keys(current_package).length > 0 &&
              <div className="package--wrapper">
                <h1 className="page-header">{current_package.title}</h1>
                {user.isLoggedIn &&
                  <ul className="nav-tabs">
                    <li className="active"><NavLink to={{ pathname: package_url }}>View</NavLink></li>
                    <li>
                      <NavLink to={{ pathname: `/package/edit`, state: { id: current_package.nid, title: current_package.title } }}>
                        Edit
                      </NavLink>
                    </li>
                  </ul>
                }
                <div className="package--location-ref">
                  <span className="label-span">Location Reference:&nbsp;</span>
                  <span>{current_package.field_location_reference && current_package.field_location_reference.label}</span>
                </div>
                {/* I'm not sure what this field is for. */}
                {/* <div className="package--locid">
          <label>LOCID:&nbsp;</label>
          <span>{current_package.field_locid_package.length > 0 && current_package.field_locid_package[0].value}</span>
        </div> */}
                <div className="package--updated">
                  <span className="label-span">Last Modified:&nbsp;</span>
                  <span>{current_package.updated}</span>
                </div>
                {current_package.field_qr_code_image &&
                  <div className="package--barcode"
                    dangerouslySetInnerHTML={{ __html: current_package.field_qr_code_image }} />
                }
                {current_package.field_pdf &&
                  <div className="package--btn-links">
                    <button className="btn btn-danger" onClick={handleClick}>PDF&nbsp; <FontAwesomeIcon icon={['far', 'file-pdf']} /></button>
                  </div>
                }
              </div>
            }
          </div>
        </Content>
      </Layout>
    </DefaultLayout>
  );
}



export default PackageTemplate

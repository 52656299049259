import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { userLogout } from '../../store/slices/user'

class LogoutPage extends Component {

  componentDidMount() {
    this.props.logout();
    this.props.history.replace('/')
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(userLogout()),
})
export default withRouter(connect(null, mapDispatchToProps)(LogoutPage))

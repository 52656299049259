import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import AuthForm from "../common/authForm/authForm.component";
import SBCALogo from "../components/logos/SBCALogo";
import "./SignIn.less";
import { loginWithEmail, signUpWithEmail, forgotPassword, changePassword } from "../store/slices/user";

/**
 * SignIn
 * @export
 * @class SignIn
 * @extends {Component}
 */
class SignIn extends Component {
  render() {
    return (
      <Row className="SignIn">
        <Col
          className="SignIn__column-left"
          style={{ minHeight: "100vh" }}
          xs={24}
          md={8}
        >
          <SBCALogo logoDark />
          <Row justify="center">
            <Col span={24} className="form-wrapper">
              <AuthForm
                onSuccess={() => {
                  // After login redirect back to homepage
                  this.props.history.push(`/`);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={8} md={16}>
          <div className="background" />
        </Col>
      </Row>
    );
  }
}
export default connect(
  (state) => ({ user: state.user }),
  (dispatch) => ({
    signUpWithEmail: (userData) => dispatch(signUpWithEmail(userData)),
    loginWithEmail: (credentials) => dispatch(loginWithEmail(credentials)),
    forgotPassword: (userData) => dispatch(forgotPassword(userData)),
    changePassword: (change) => dispatch(changePassword(change)),
  })
)(SignIn);

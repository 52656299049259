import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 20px;

  * {
    font-family: Lato, sans-serif;
    font-size: 16px;
  }
`;

const SectionHeader = styled.div`
  text-transform: uppercase;
  margin: 4px 0;
`

const SlideNavSection = ({header, children, ...props}) => {

  return (
    <Wrapper className="slide-nav__section">
      <SectionHeader className="slide-nav__section--header">{header}</SectionHeader>
      {children}
    </Wrapper>
  );
};

export default SlideNavSection

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Space, Table, message } from 'antd';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchOutlined } from "@ant-design/icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { getMyPackages } from "../../store/slices/packages";
import { getSinglePackage } from "../../store/slices/packages";
import { ThreeDots } from 'react-loader-spinner';
import dayjs from 'dayjs';
import axios from "axios";
import Highlighter from 'react-highlight-words';
import "../Global/default.template.less";

const MyPackages = () => {
  const uid = localStorage.getItem("SBCA__user");
  // const [number, SetNumber] = useState(1);
  const [refresh, SetRefresh] = useState(1);
  const [dataSource, SetDataSource] = useState([]);

  const dispatch = useDispatch();
  // const Joblist = useSelector((state) => state?.packages?.data?.my_packages);
  // const list = Joblist?.slice((number - 1) * 25, (number - 1) * 25 + 25);
  // const total = Joblist?.length;
  const id = JSON?.parse(uid).uid;

  // Setup table column search
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  // const handleClose = (close) => {
  //   close();
  // };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: true,
              });
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <NavLink to={{ pathname: record.url }}>#
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        </NavLink>
      ) : (
        <NavLink to={{ pathname: record.url }}>#{record.jobnumber}</NavLink>
      ),
  });

  // Table columns
  const columns = [
    {
      title: 'Job Number',
      dataIndex: 'jobnumber',
      key: 'jobNumber',
      sorter: (a, b) => a.jobnumber.localeCompare(b.jobnumber),
      render: (_, record) => (
        <NavLink to={{ pathname: record.url }}>#{record.jobnumber}</NavLink>
      ),
      ...getColumnSearchProps('jobnumber'),
    },
    {
      title: 'Updated date',
      dataIndex: 'updated',
      key: 'updated',
      sorter: (a, b) => dayjs(a.updated).unix() - dayjs(b.updated).unix()
    },
    {
      title: 'Published',
      dataIndex: 'published',
      key: 'published',
      className: 'txt-center',
      render: (icon) => (
        <div className="text-center bold" dangerouslySetInnerHTML={{ __html: icon }} />
      ),
    },
    {
      title: 'Created by',
      dataIndex: 'created',
      key: 'created'
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      className: 'txt-center',
      render: (pack) => (
        <button className="btn btn-danger btn-sm" onClick={() => handleClick(pack)}>
          PDF &nbsp; <FontAwesomeIcon icon={['far', 'file-pdf']} />
        </button>
      )
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <NavLink to={{ pathname: `/package/edit`, state: { id: record.id, title: record.title } }}>Edit</NavLink>
      ),
    },
  ];

  useEffect(() => {
    const dataSource = [];
    setTimeout(function () {
      dispatch(getMyPackages(id)).then(x => {
        if (x.payload) {
          const packages = x.payload;
          console.log(packages);

          packages.map((pack, id) => {
            const subscriber = pack?.field_subscriber_reference?.label;
            const subscriber_url =
              subscriber !== ""
                ? subscriber.toLowerCase().replace(/ /g, "-")
                : "qualtim";

            return dataSource.push({
              key: id,
              id: pack?.nid,
              title: pack?.title,
              jobnumber: pack?.field_job_number?.[0]?.value,
              url: `/package/${subscriber_url}/${pack.nid}`,
              updated: pack?.created,
              published: pack?.status === "1" ? "&#10003;" : "&#10006;",
              created: pack?.created_by?.name[0].value,
              pdf: pack
            });
          });

          SetDataSource(dataSource);
          SetRefresh(0);
        }
      });
    }, 500);
  }, [id, dispatch]);
  // const handlePageChange = (e) => {
  //   SetNumber(e);
  // };
  const refreshPackages = (e) => {
    SetRefresh(1);
    const dataSource = [];
    setTimeout(function () {
      dispatch(getMyPackages(id)).then(x => {
        if (x.payload) {
          const packages = x.payload;
          console.log(packages);

          packages.map((pack, id) => {
            const subscriber = pack?.field_subscriber_reference?.label;
            const subscriber_url =
              subscriber !== ""
                ? subscriber.toLowerCase().replace(/ /g, "-")
                : "qualtim";

            return dataSource.push({
              key: id,
              id: pack?.nid,
              title: pack?.title,
              jobnumber: pack?.field_job_number?.[0]?.value,
              url: `/package/${subscriber_url}/${pack.nid}`,
              updated: pack?.created,
              published: pack?.status === "1" ? "&#10003;" : "&#10006;",
              created: pack?.created_by?.name[0].value,
              pdf: pack
            });
          });

          SetDataSource(dataSource);
          SetRefresh(0);
        }
      });
    }, 500);
};

const handleClick = (e) => {
  message.info("Please wait for a few minutes while we are generating your PDF");

  (async () => {
    //   const collectionOfPdf = await dispatch(getMyPackages(id));
    const resultAction = await dispatch(getSinglePackage(e.nid));
    const originalPromiseResult = unwrapResult(resultAction);
    const pdf_ref = originalPromiseResult?.[0]?.field_pdf_reference
    const pdf_data = pdf_ref !== undefined && Object.values(pdf_ref).map((key) => key.field_pdf);

    // const NotesString=(e?.field_notes?.[0]?.value)===undefined?"":e?.field_notes?.[0]?.value
    const subscriber_name = (originalPromiseResult?.[0].field_subscriber_reference?.label) === undefined ? "" : originalPromiseResult?.[0].field_subscriber_reference?.label
    const NotesString = 'Publication printed or distributed by ' + subscriber_name + ' with permission of the Structural Building Components Association (SBCA) and the Truss Plate Institute (TPI).'
    const customerNamestring = (e?.field_custumer_name?.[0]?.value) === undefined ? "" : e?.field_custumer_name?.[0]?.value
    const logo = (originalPromiseResult?.[0].field_subscriber_reference?.logo) === undefined ? "" : originalPromiseResult?.[0].field_subscriber_reference?.logo
    const address = originalPromiseResult?.[0].field_address
    const fullAddress = address?.[0].address_line1 + ', ' + address?.[0].locality + ', ' + address?.[0].administrative_area + ' ' + address?.[0].postal_code
    const sample = (originalPromiseResult?.[0].field_location_reference?.testing) === undefined ? "" : originalPromiseResult?.[0].field_location_reference?.testing
    const data = {
      nid: originalPromiseResult?.[0].nid,
      jobNumber: e?.field_job_number?.[0]?.value,
      ExpirationDate: "2022-10-06",
      Notes: NotesString,
      logo: logo,
      company: subscriber_name,
      PackageDetails: pdf_data,
      Address: fullAddress,
      sample: sample,
      customerName: customerNamestring,
    };

    var xy = window.open(`/loading`)
    console.log(xy);

    pdf_data ? axios.post(process.env.REACT_APP_API_URLQ, data).then((res) => xy.location = res.data.path) : alert("PDF is not there")
  })();
};

  return (
    <>
      {refresh > 0 || dataSource > 0 ?
        <ThreeDots className="loader" color="#DDDDDD" height={50} width={50} />
        :
        <>
          <h1 className="page-header">My Packages</h1>
          <p>
            The table below lists jobsite packages that have been created for your
            location.
          </p>
          <p className="no-mrgn-btm">
            <a className="btn btn-danger" href="/packages/add">
              <FontAwesomeIcon icon={faPlus} /> New Jobsite Package
            </a>

            <button className="btn btn-success refreshBtn" onClick={refreshPackages}>
              <i className="fa fa-refresh"></i> Refresh
            </button>
          </p>
          <div className="table-responsive">
            {dataSource &&
              <Table className="PreviewTable" dataSource={dataSource} columns={columns} pagination={{ pageSize: 25 }} />
            }
          </div>
        </>
      }
    </>
  );
};

export default MyPackages;

import React, { useState } from "react";
import { Layout } from "antd";
import Navbar from "../components/Navbar";
import { DrawerNav } from '../components';

const { Header } = Layout;

export const DefaultLayout = ({ className, children, ...props }) => {
  // Toggle drawer visibility over entire page
  const [drawerNavVisible, setDrawerNavVisible] = useState(false);
  const toggleDrawer = () => setDrawerNavVisible(!drawerNavVisible);

  return (
    <>
      <DrawerNav className="DrawerNav" toggleDrawer={toggleDrawer} visible={drawerNavVisible} />
      <Layout className={className} style={{ minHeight: "100vh" }}>
        <Header>
          <Navbar toggleDrawer={toggleDrawer} />
        </Header>
        {children}
      </Layout>
    </>
  )
};

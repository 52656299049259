import React from "react";
import styled from "styled-components";

// import "./worker.config.js";

import "./themes/antd-sbca.less";
import "./themes/sbca-global.less";
import "../node_modules/antd/dist/reset.css";

import "./App.css";
import ROUTES, { RenderRoutes } from "./routes/routes";
// import { ModalProvider, ModalContainer } from "./hooks/useModal";
import { checkUserAuthStatus } from "./store/slices/user";
import { Provider, connect } from "react-redux";
import { configureAppStore } from "./store/configureStore";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5T7QRZH",
};

TagManager.initialize(tagManagerArgs);

const AppContainer = styled.div`
  background: linear-gradient(45deg, red, blue);
`;

const store = configureAppStore();

function App({ checkUserAuthStatus }) {
  React.useEffect(() => {
    checkUserAuthStatus();
  }, [checkUserAuthStatus]);

  return (
    <div className="app-wrapper-g">
      <AppContainer>
        <RenderRoutes routes={ROUTES} />
      </AppContainer>
    </div>
  );
}

const ConnectedApp = connect(
  (state) => ({ user: state.user }),
  (dispatch) => ({
    checkUserAuthStatus: () => dispatch(checkUserAuthStatus()),
  })
)(App);

export default () => (
  <Provider store={store}>
    <ConnectedApp />
  </Provider>
);
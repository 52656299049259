import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "../../components/Select";
import SelectState from "../../common/SelectState";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { getSinglePackage } from "../../store/slices/packages";
// import { getSubscribers } from "../../store/slices/packages";
import { unwrapResult } from "@reduxjs/toolkit";
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Layout, message, Row, Col } from "antd";
import dayjs from "dayjs";
import { updatePackages } from "../../store/slices/packages";
import { getDocuments } from "../../store/slices/documents";
import { ThreeDots } from 'react-loader-spinner';
import { DefaultLayout } from "../../layout/DefaultLayout";
const { Content } = Layout;

const PackagesEditForm = (props) => {
  const userid = props.location.state.id;
  const title = props.location.state.title
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, SetList] = useState();
  const [loader, SetLoader] = useState(1);
  const [error, SetError] = useState({});
  const [allpdf, setAllpdf] = useState([]);
  const [userLocations, SetUserLocations] = useState();
  const user = localStorage.getItem("SBCA__user");
  const userData = JSON?.parse(user);

  const [data, SetData] = useState({
    title: "",
    field_job_number: "",
    field_subscriber_reference: "",
    field_location_reference: "",
    field_pdf_reference: list,
    body: "",
    field_notes: "",
    field_customer_name: "",
    field_expDate: "",
    user_location: userData?.location?.length === 1 ? userData?.location?.[0]?.nid : "",
    subscriberID: userData?.company?.nid,
    address_line1: "",
    address_line2: "",
    locality: "",
    administrative_area: "",
    postal_code: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const resultAction = await dispatch(getSinglePackage(userid));
        const hintPdfRef = await dispatch(getDocuments())
        const allPdfRef = unwrapResult(hintPdfRef)?.map(key => ({ "id": key.nid, "title": `${key.title} (${key.nid})` }));

        const originalPromiseResult = unwrapResult(resultAction);
        const pdf_ref = originalPromiseResult?.[0]?.field_pdf_reference
        const pdf_data = pdf_ref !== null && pdf_ref !== undefined && Object.values(pdf_ref)?.map((key) => { return ({ id: key.id, title: `${key.label}(${key.id})` }) });
        const finalList = Object.assign({}, pdf_data)

        let x = {
          id: originalPromiseResult?.[0].nid,
          title: originalPromiseResult?.[0].title,
          body: originalPromiseResult?.[0].body,
          alias: originalPromiseResult?.[0].alias,
          field_job_number: originalPromiseResult?.[0]?.field_job_number?.[0].value,
          field_subscriber_reference: (originalPromiseResult?.[0]?.field_subscriber_reference?.id > 0) ? originalPromiseResult?.[0]?.field_subscriber_reference?.id : '',
          field_location_reference: (originalPromiseResult?.[0]?.field_location_reference?.id > 0) ? originalPromiseResult?.[0]?.field_location_reference?.id : '',
          field_pdf_reference: list,
          field_notes: originalPromiseResult?.[0]?.field_notes?.[0]?.value,
          field_customer_name: originalPromiseResult?.[0]?.field_customer_name?.[0]?.value,
          field_expDate: originalPromiseResult?.[0]?.unpublish_on ? originalPromiseResult?.[0]?.unpublish_on : dayjs().add(6, 'M').format('YYYY-MM-DD'),
          user_location: (originalPromiseResult?.[0]?.user_location) ? originalPromiseResult?.[0]?.user_location : data?.user_location,
          address_line1: originalPromiseResult?.[0]?.field_address?.[0]?.address_line1,
          address_line2: originalPromiseResult?.[0]?.field_address?.[0]?.address_line2,
          locality: originalPromiseResult?.[0]?.field_address?.[0]?.locality,
          administrative_area: originalPromiseResult?.[0]?.field_address?.[0]?.administrative_area,
          postal_code: originalPromiseResult?.[0]?.field_address?.[0]?.postal_code,
        };

        console.log(originalPromiseResult, x);

        SetData({ ...data, ...x });
        SetUserLocations({ ...userData.location });
        SetList({ ...list, ...finalList })
        setAllpdf([...allpdf, ...allPdfRef])
        SetLoader(0);

        // handle result here
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    })();// eslint-disable-next-line
  }, [dispatch]);
  const handleChange = (event) => {
    SetData({ ...data, [event.target.name]: event.target.value });
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (validation()) {
      console.log(data);
      SetLoader(1);
      dispatch(updatePackages(data)).then((response) => {
        if (response.payload.status === 1) {
          message.success("Your changes are saved.");
          history.push("/my-packages")
        }
      });
    }
  };

  const handleAdd = (e) => {
    e.preventDefault()
    SetList({ ...list, [e.target.name]: e.target.value })
    // SetData({...data,field_pdf_reference:list})
  }
  const validation = () => {
    var err = {};
    if (data.field_job_number === "") {
      err["field_job_number"] = "This field is required";
    }
    if (data.field_expDate === "") {
      err['field_expDate'] = "This field is required";
    }
    if (data.user_location === "") {
      err["user_location"] = "This field is required";
    }
    if (data.address_line1 === "") {
      err["address_line1"] = "This field is required";
    }
    if (data.locality === "") {
      err["locality"] = "This field is required";
    }
    if (data.administrative_area === "") {
      err["administrative_area"] = "This field is required";
    }
    if (data.postal_code === "") {
      err["postal_code"] = "This field is required";
    }
    SetError({ ...err });
    return Object.values(err).length < 1 && true;
  };

  const subscriber = data?.field_subscriber_reference
  const subscriber_url = subscriber !== "" ? subscriber?.toLowerCase().replace(/ /g, "-") : "qualtim"
  const package_url = `/package/${subscriber_url}/${data.id}`
    ; return (
      <>
        <DefaultLayout
          className="package-edit"
          style={{ minHeight: "100vh" }}
        >
          <Layout
            style={{ flex: 1 }}
            className="main-content"
          >
            <Content style={{ padding: "0px 30px" }}>
              {(loader === 1) ? <ThreeDots className="loader" color="#DDDDDD" height={50} width={50} /> : <>
                <h1 className="page-header">{title}</h1>
                <ul className="nav-tabs">
                  <li><NavLink to={{ pathname: package_url }}>View</NavLink></li>
                  <li className="active">
                    <NavLink to={{ pathname: `/package/edit`, state: { id: data?.id, title: data?.title } }}>
                      Edit
                    </NavLink>
                  </li>
                </ul>
                <form>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div
                        className={classNames(
                          "form-item",
                          error.field_job_number !== "" && error.field_job_number && "error"
                        )}
                      >
                        <label>
                          Job Number{" "}
                          <span className="form-required" title="This field is required.">
                            *
                          </span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="field_job_number"
                          value={data.field_job_number}
                          onChange={handleChange}
                        />
                        <span>Please enter a unique job number for this package</span>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div
                        className={classNames(
                          "form-item",
                          error.field_customer_name !== "" && error.field_customer_name && "error"
                        )}
                      >
                        <label>Customer</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          name="field_customer_name"
                          value={data.field_customer_name}
                          onChange={handleChange}
                        />
                        <span>
                          The name of the customer or client that this package is being
                          generated for.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {userData?.location?.length > 1 ?
                    <Row gutter={24}>
                      <Col xs={24} md={12}>
                        <div
                          className={classNames(
                            "form-item",
                            error.user_location !== "" && error.user_location && "error"
                          )}
                        >
                          <label>Select Billing Location</label>
                          <Select
                            method={SetData}
                            options={userLocations}
                            placeholder={"Select billing location"}
                            default_value={data?.field_location_reference}
                            field_name="user_location"
                            data={data}
                          />
                          {error.user_location !== "" &&
                            <span className="error error-msg">{error.user_location}</span>
                          }
                        </div>
                      </Col>
                    </Row> : ''
                  }
                  <Row gutter={24}>
                    <Col xs={24} md={8}>
                      <div
                        className={classNames(
                          "form-item",
                          error.field_expDate !== "" && error.field_expDate && "error"
                        )}
                      >
                        <label>
                          Expiration Date{" "}
                          <span className="form-required" title="This field is required.">
                            *
                          </span>
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          name="field_expDate"
                          placeholder="date"
                          defaultValue={data?.field_expDate}
                          onChange={handleChange}
                        />
                        <span className="help-block">Please enter the date that this package should expire</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-item">
                    <label>Notes</label>
                    <span>
                      Include any custom notes related to this jobsite package here.
                      Data entered in this field will only be visible to package authors
                      and site administrators
                    </span>
                    <textarea
                      className="form-control"
                      name="field_notes"
                      cols="60"
                      rows="2"
                      value={data.field_notes}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={classNames(
                      "form-item",
                      error.jobSitePackage !== "" && error.jobSitePackage && "error"
                    )}
                  >
                    <label>
                      Jobsite PDF Reference Package{" "}
                      <span className="form-required" title="This field is required.">
                        *
                      </span>
                    </label>
                    <span>Please choose a package from the list below</span>
                    {
                      list && <>
                        {Object.values(list)?.map((key, id) => {
                          return (
                            <Autocomplete
                              key={id}
                              disablePortal
                              className="hintDisplay"
                              name={id}
                              options={allpdf}
                              defaultValue={key}
                              getOptionLabel={(option) => option.title || ""}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onChange={(key, newValue) => {
                                SetList({ ...list, [id]: newValue })
                                SetData({ ...data, field_pdf_reference: { ...list, [id]: newValue } });
                              }}
                              renderInput={(params) => <TextField {...params} label="" />}
                            />
                          );
                        })}
                      </>
                    }
                    <button onClick={handleAdd}>Add</button>
                  </div>
                  <div className="form-group">
                    <h3 className="bottom-border">Jobsite Address</h3>
                    <span>
                      Please enter the street address including specific building
                      details for the jobsite using the fields below.
                    </span>
                    <Row gutter={24}>
                      <Col xs={24} md={12}>
                        <div
                          className={classNames(
                            "form-item",
                            error.address_line1 !== "" && error.address_line1 && "error"
                          )}
                        >
                          <label>
                            Address{" "}
                            <span
                              className="form-required"
                              title="This field is required."
                            > *
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="address_line1"
                            value={data.address_line1}
                            placeholder=""
                            onChange={handleChange}
                          />
                          {error.address_line1 !== "" && (
                            <span className="error error-msg">{error.address_line1}</span>
                          )}
                        </div>
                        <div className="form-item">
                          <label>Address 2</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address_line2"
                            value={data.address_line2}
                            placeholder=""
                            onChange={handleChange}
                          />
                        </div>
                        <div
                          className={classNames(
                            "form-item",
                            error.locality !== "" && error.locality && "error"
                          )}
                        >
                          <label>
                            City{" "}
                            <span
                              className="form-required"
                              title="This field is required."
                            > *
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="locality"
                            placeholder=""
                            value={data.locality}
                            onChange={handleChange}
                          />
                          {error.locality !== "" && (
                            <span className="error error-msg">{error.locality}</span>
                          )}
                        </div>
                        <div
                          className={classNames(
                            "form-item",
                            error.administrative_area !== "" && error.administrative_area && "error"
                          )}
                        >
                          <label>
                            State/Province{" "}
                            <span
                              className="form-required"
                              title="This field is required."
                            > *
                            </span>
                          </label>
                          <SelectState
                            val={data.administrative_area}
                            name="administrative_area"
                            method={SetData}
                            data1={data}
                          />
                          {error.administrative_area !== "" && (
                            <span className="error error-msg">{error.administrative_area}</span>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={12} md={6}>
                        <div
                          className={classNames(
                            "form-item",
                            error.postal_code !== "" && error.postal_code && "error"
                          )}
                        >
                          <label>
                            Zip Code{" "}
                            <span
                              className="form-required"
                              title="This field is required."
                            > *
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="postal_code"
                            placeholder=""
                            value={data.postal_code}
                            onChange={handleChange}
                          />
                          {error.postal_code !== "" && (
                            <span className="error error-msg">{error.postal_code}</span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <button className="btn btn-danger btn-md" onClick={handleClick}>
                    Submit
                  </button>
                </form>

              </>
              }

            </Content>
          </Layout>
        </DefaultLayout>
      </>
    );
};

export default PackagesEditForm;

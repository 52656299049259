import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Layout, message } from "antd";
// import axios from "axios";
import classNames from "classnames";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateContact } from "../../store/slices/packages";
import { getSubmission } from "../../store/slices/packages";
import "./package.template.less";
import { DefaultLayout } from "../../layout/DefaultLayout";
const { Content } = Layout;

const ContactEdit = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, SetError] = useState({});
  const [data, SetData] = useState({
    email: [{ value: "" }],
    firstname: [{ value: "" }],
    lastname: [{ value: "" }],
    comments: [{ value: "" }]
  });

  useEffect(() => {
    (async () => {
      try {
        const resultAction = await dispatch(getSubmission(id));
        const originalPromiseResult = unwrapResult(resultAction);
        let values = {
          nid: originalPromiseResult[0]?.nid,
          email: [{ value: originalPromiseResult[0]?.field_e_mail[0]?.value }],
          firstname: [{ value: originalPromiseResult[0]?.field_first_name[0]?.value }],
          lastname: [{ value: originalPromiseResult[0]?.field_last_name[0]?.value }],
          comments: [{ value: originalPromiseResult[0]?.body }]
        }
        SetData({ ...data, ...values });
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    })();
  }, [data, dispatch, id]);
  const validation = () => {
    var err = {};
    if (data.email?.[0].value === "") {
      err["email"] = "this field is required";
    }
    if (data.firstname?.[0].value === "") {
      err["firstname"] = "this field is required";
    }
    if (data.lastname?.[0].value === "") {
      err["lastname"] = "this field is required";
    }
    SetError({ ...err });
    return Object.values(err).length < 1 && true;
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(data);
    if (validation()) {
      dispatch(
        updateContact({ body: { val: data } })
      ).then((data) => {
        if (data.payload) {
          message.success('The changes have been saved.');
          history.push("/submissions");
        } else {
          message.error('There was an error saving data. Please contact site administrator.');
        }
      });
    }
  };
  const handleChange = (event) => {
    SetData({ ...data, [event.target.name]: [{ value: event.target.value }] });
  };

  return (
    <DefaultLayout
      className="contact-edit"
      style={{ minHeight: "100vh" }}
    >
      <Layout
        style={{ flex: 1 }}
        className="main-content"
      >
        <Content style={{ padding: "0px 30px" }}>
          <>
            <h1 className="page-header">Submission #{id}</h1>
            <ul className="nav-tabs">
              <li>
                <NavLink to={{ pathname: `/submission/${id}` }}>View</NavLink>
              </li>
              <li className="active">
                <NavLink to={{ pathname: `/submission/${id}/edit` }}>Edit</NavLink>
              </li>
            </ul>
            <>
              <form className="form-group contact-form">
                <div
                  className={classNames(
                    "form-item",
                    error.firstname !== "" && error.firstname && "error"
                  )}
                >
                  <label>
                    First
                    <span className="form-required" title="This field is required.">
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data?.firstname[0]?.value}
                    name="firstname"
                  />
                </div>

                <div
                  className={classNames(
                    "form-item",
                    error.lastname !== "" && error.lastname && "error"
                  )}
                >
                  <label>
                    Last
                    <span className="form-required" title="This field is required.">
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data?.lastname[0]?.value}
                    name="lastname"
                  />
                </div>
                <div
                  className={classNames(
                    "form-item",
                    error.email !== "" && error.email && "error"
                  )}
                >
                  <label>
                    Email
                    <span className="form-required" title="This field is required.">
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data?.email[0]?.value}
                    name="email"
                  />
                </div>
                <label>
                  Comments
                  <span
                    className="form-required"
                    title="This field is required."
                  ></span>
                </label>
                <textarea
                  className="form-control"
                  name="comments"
                  cols="60"
                  rows="5"
                  defaultValue={data?.comments[0]?.value}
                  onChange={handleChange}
                />
              </form>
              <button className="btn btn-danger btn-md" onClick={handleClick}>
                Submit
              </button>
            </>
          </>

        </Content>
      </Layout>
    </DefaultLayout>
  );
};

export default ContactEdit;

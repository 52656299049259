import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import SlideNavLinkItem from '../NavLinkItem/slidenav-link-item.component';

const Wrapper = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
`;

const ProfileImageWrapper = styled.div`
    width: 49px;
    height: 49px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

// const ProfileImage = styled.div`
//     width: 100%;
//     height: 100%;
//     background-image: ${({ url }) => `url(${url})`};
//     background-size: cover;
// `;

const UserName = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    font-family: Lato, sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
`;

const NameAndProfileLink = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 15px;

    .profile__link-item {
        margin-bottom: 0;

        * {
            line-height: 1;
        }
    }
`;

// const imageExists = (url, callback) => {
//   const img = new Image();
//   img.onload = function () {
//     callback(true);
//   };
//   img.onerror = function () {
//     callback(false);
//   };
//   img.src = url;
// };

const SlideNavHeader = ({ ...props }) => {
  const user = useSelector((state) => state.user);
  const defaultImage = <FontAwesomeIcon icon={faUserAlt} size="3x" />;
  let userName = 'Guest Visitor';
  let guest = true;
  // const [imgExists, setImgExists] = useState(false);
  // const [imgChecked, setImgChecked] = useState(false);
  let profileImage = defaultImage;
  if (user && user.isLoggedIn) {
    const { firstName, lastName } = user.data;
    // const { firstName, lastName, profile_image } = user.data;
    userName = `${firstName} ${lastName}`;
    guest = false;

    // check validity of profile image url
    // if (!imgChecked) {
    //   imageExists(profile_image, (exists) => {
    //     setImgExists(exists);
    //     setImgChecked(true);
    //   });
    // }
    // if (imgExists) {
    //   profileImage = <ProfileImage url={profile_image} />;
    // }
  }

  return (
    <Wrapper className="slide-nav__header">
      <ProfileImageWrapper>{profileImage}</ProfileImageWrapper>
      <NameAndProfileLink>
        <UserName>{userName}</UserName>
        { !guest ?
          <SlideNavLinkItem
            className="profile__link-item"
            icons={false}
            linkItem={{
              key: 'account-and-profile',
              text: 'View Profile',
              to: '/user',
            }}
          />
        : '' }
      </NameAndProfileLink>
    </Wrapper>
  );
};

export default SlideNavHeader;

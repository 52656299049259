import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestUserDetails } from "../../store/slices/user";
import "./user.template.less";
// import { unwrapResult } from "@reduxjs/toolkit";
import { NavLink } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { Layout } from "antd";
import { DefaultLayout } from "../../layout/DefaultLayout";
const { Content } = Layout;

const UserDetail = () => {
  const uid = localStorage.getItem("SBCA__user");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.data);
  const [loaded, SetLoaded] = useState(0);
  useEffect(() => {
    (async () => {
      const id = JSON?.parse(uid).uid;
      dispatch(requestUserDetails(id)).then(res => {
        SetLoaded(1)
      });
    })();
  }, [uid, dispatch]);
  return (
    <DefaultLayout
      className="user-detail"
      style={{ minHeight: "100vh" }}
    >
      <Layout
        style={{ flex: 1 }}
        className="main-content"
      >
        <Content style={{ padding: "0px 30px" }}>
          <>
            {
              !(loaded > 0) ? <ThreeDots className="loader" color="#DDDDDD" height={50} width={50} /> : <>
                <div className="user-detail--wrapper">
                  <h1 className="page-header">{userData?.name}</h1>
                  <ul className="nav-tabs">
                    <li className="active">
                      <NavLink to="/user">View</NavLink>
                    </li>
                    <li>
                      <NavLink to="/user/edit">Edit</NavLink>
                    </li>
                  </ul>
                  <div className="field-item inline">
                    <span className="label-span">Full Name: </span>
                    <span>
                      {userData?.firstName} {userData?.lastName}
                    </span>
                  </div>
                  <div className="field-item inline">
                    <span className="label-span">Company: </span>
                    <NavLink to={`/subscribers/${userData?.company?.nid}`}>
                      {userData?.company?.title}
                    </NavLink>
                  </div>
                  <h3>History</h3>
                  <span className="label-span">Member for:&nbsp;</span>
                  <span>{userData?.memberFor}</span>
                </div>
                <div className="PreviewTable table-responsive">
                  {/* Show all locations attributed to the User Profile */}
                  <h2 className="sub-header">Location Information</h2>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Location Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.location?.map((key) => {
                        return (
                          <>
                            <tr key={key?.nid}>
                              <td>{key?.nid}</td>
                              <td>{key?.title}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            }
          </>
        </Content>
      </Layout>
    </DefaultLayout>
  );
};
export default UserDetail;

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
`;

const FlexSpacer = ({className = 'flex-spacer'}) => <Wrapper className={className} />;

export default FlexSpacer;

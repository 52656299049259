import React from "react";
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userLogout } from '../../../../store/slices/user';
import { connect } from "react-redux";

const Wrapper = styled.div`
  margin: 2px 0;
  height: 28;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
const LinkText = styled.div`
`

const LinkIcon = styled.div`
  margin-right: 3px;
  width: 22px;
  align-items: 'flex-start';

  &.left-margin {
    margin-left: 6px;
  }
`;

const SlideNavLinkItem = ({ icons = true, linkItem: {key, text, to, auth, icon}, ...props }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const handleLogout = () => {
    history.push("/");
    props.dispatch(
      userLogout()
    ).then((res) => {
      console.log(res);
    });
  }

  const renderIcon = !icons ? null : (
    <LinkIcon
      className={
        classNames(
          auth && (user && !user.isLoggedIn) && 'hidden'
        )
      }
    >
      <FontAwesomeIcon icon={icon} />
    </LinkIcon>
  );
  const LinkItemText = (key === 'sign-out' || key === 'sign-in') ? (
    <LinkText
      className={classNames(
        "left-margin",
        user && user.isLoggedIn && key === 'sign-in' && 'hidden',
        user && !user.isLoggedIn && key === 'sign-out' && 'hidden',
      )}
      onClick = {handleLogout}
    >
      {text}
    </LinkText>
  ) : <LinkText
      className={
        classNames(
          auth && (user && !user.isLoggedIn) && 'hidden'
        )
      }
    >{text}</LinkText>;
  const signOutIcon = (key === 'sign-out' || key === 'sign-in') ? (
    <LinkIcon
      className={classNames(
        "left-margin",
        user && user.isLoggedIn && key === 'sign-in' && 'hidden',
        user && !user.isLoggedIn && key === 'sign-out' && 'hidden',
      )} >
      <FontAwesomeIcon icon={icon} />
    </LinkIcon>
  ) : null;

  return (
    <Wrapper
      onClick={() => {
        // perform onClick prop before navigating
        props.onClick && props.onClick();
        history.push(to);
        window.scrollTo(0, 0);
      }}
      {...props}
    >
      {renderIcon}
      {LinkItemText}
      {signOutIcon}
    </Wrapper>
  );
}

export default connect((state) => ({
  user: state.user
}))(SlideNavLinkItem);
import React from "react";

import logo from "../../assets/images/logos/sbca-docs-logo-new.png";
import logoDark from "../../assets/images/logos/sbca-docs-logo-new.png";
import "./SBCALogo.less";

export default function Logo(props) {
  return (
    <div className="sbca-logo">
      <a href="/">
        <img
          alt="SBCA Logo"
          src={props.dark ? logoDark : logo}
        />
      </a>
    </div>
  );
}

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SlideNavBody = ({children}) => {

  return (
    <Wrapper className="slide-nav__body">
      {children}
    </Wrapper>
  );
};

export default SlideNavBody

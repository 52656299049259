import React, { useEffect, useState } from "react";
import { Layout, Row, Col } from "antd";
import { useHistory } from "react-router";
import Select from "../../components/Select";
import SelectState from "../../common/SelectState";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import "./default.template.less";
import "./package-form.template.less";
import { getDocuCollections } from "../../store/slices/documents";
import { DefaultLayout } from "../../layout/DefaultLayout";
const { Content } = Layout;
const PackageForm = (props) => {
  const formData = props?.location?.state;
  const user = localStorage.getItem("SBCA__user");
  const userData = JSON?.parse(user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, SetChecked] = useState();
  const [userLocations, SetUserLocations] = useState();
  const [error, SetError] = useState({});
  const [data, SetData] = useState({
    field_job_number: "",
    field_expDate: moment().add(6, "M").format("YYYY-MM-DD"),
    field_notes: "",
    field_documents: "",
    field_customer_name: "",
    user_location: userData?.location?.length === 1 ? userData?.location?.[0]?.nid : "",
    subscriberID: userData?.company?.nid,
    address_line1: "",
    address_line2: "",
    locality: "",
    administrative_area: "",
    postal_code: "",
    field_qr_code: "https://app.sbcacomponents.com/node/[node:nid]",
    uid: userData?.uid,
  });
  useEffect(() => {
    const userDetails = JSON?.parse(localStorage.getItem("SBCA__user"));
    dispatch(getDocuCollections());
    if (userDetails) {
      console.log(userDetails);
      SetData(data => ({ ...data, ...userDetails }));
      SetChecked(userDetails.field_documents);
      SetUserLocations({ ...userDetails.location });
    }

    // Restore form data from preview page+
    if (formData) {
      SetData(data => ({ ...data, ...formData }));
      SetChecked(formData.field_documents);
    }
  }, [dispatch, formData]);

  const jobData = useSelector(
    (state) => state.documents?.data?.docu_collections[0]
  );

  const handleChange = (event) => {
    SetData({ ...data, [event.target.name]: event.target.value });
  };
  const handleCheck = (event) => {
    SetChecked(event.target.value);
    SetData({ ...data, [event.target.name]: event.target.value });
  };
  const handleClick = (e) => {
    e.preventDefault();
    console.log(data);
    if (validation()) {
      history.push({ pathname: "/preview", state: data });
    }
  };
  const validation = () => {
    var err = {};
    if (data.field_job_number === "") {
      err["field_job_number"] = "This field is required";
    }
    if (data.field_documents === "") {
      err["field_documents"] = "This field is required";
    }
    if (data.user_location === "") {
      err["user_location"] = "This field is required";
    }
    if (data.address_line1 === "") {
      err["address_line1"] = "This field is required";
    }
    if (data.locality === "") {
      err["locality"] = "This field is required";
    }
    if (data.administrative_area === "") {
      err["administrative_area"] = "This field is required";
    }
    if (data.postal_code === "") {
      err["postal_code"] = "This field is required";
    }

    SetError({ ...err });
    return Object.values(err).length < 1 && true;
  };

  return (
    <>
      <DefaultLayout
        className="package-form"
        style={{ minHeight: "100vh" }}
      >
        <Layout
          style={{ flex: 1 }}
          className="main-content"
        >
          <Content style={{ padding: "0px 30px" }}>
            <div className="FormContainer">
              <h1 className="page-header no-mrgn-top">Create Jobsite Package</h1>
              <h3 className="legend-title">Package Information</h3>
              <form>
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <div
                      className={classNames(
                        "form-item",
                        error.field_job_number !== "" &&
                        error.field_job_number &&
                        "error"
                      )}
                    >
                      <label>
                        Job Number{" "}
                        <span
                          className="form-required"
                          title="This field is required."
                        >
                          *
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="field_job_number"
                        placeholder="Number"
                        defaultValue={data?.field_job_number}
                        onChange={handleChange}
                      />
                      <span>Please enter a unique job number for this package</span>
                      {error.field_job_number !== "" && (
                        <span className="error error-msg">
                          {error.field_job_number}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="form-item">
                      <label>Customer or Project Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        name="field_customer_name"
                        defaultValue={data?.field_customer_name}
                        onChange={handleChange}
                      />
                      <span>
                        The name of the customer or client that this package is being
                        generated for.
                      </span>
                      {error.field_customer_name !== "" && (
                        <span className="error error-msg">
                          {error.field_customer_name}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                {userData?.location?.length > 1 ?
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div className="form-item">
                        <label>Select Billing Location</label>
                        <Select
                          method={SetData}
                          options={userLocations}
                          placeholder={"Select billing location"}
                          // default_value={data?.user_location}
                          field_name="user_location"
                          data={data}
                        />
                        {error.user_location !== "" && (
                          <span className="error error-msg">{error.user_location}</span>
                        )}
                      </div>
                    </Col>
                  </Row> : ''
                }
                <Row gutter={24}>
                  <Col xs={24} md={8}>
                    <div
                      className={classNames(
                        "form-item",
                        error.field_expDate !== "" && error.field_expDate && "error"
                      )}
                    >
                      <label>
                        Expiration Date{" "}
                        <span
                          className="form-required"
                          title="This field is required."
                        >
                          *
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        name="field_expDate"
                        placeholder="date"
                        defaultValue={data?.field_expDate}
                        onChange={handleChange}
                      />
                      <span className="help-block">
                        Please enter the date that this package should expire
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className="form-item">
                  <label>Notes</label>
                  <span>
                    Include any custom notes related to this jobsite package here.
                    Data entered in this field will only be visible to package authors
                    and site administrators
                  </span>
                  <textarea
                    className="form-control"
                    name="field_notes"
                    cols="60"
                    rows="2"
                    defaultValue={data?.field_notes}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={classNames(
                    "form-item",
                    error.field_documents !== "" && error.field_documents && "error"
                  )}
                >
                  <label>
                    Jobsite Package{" "}
                    <span className="form-required" title="This field is required.">
                      *
                    </span>
                  </label>
                  {error.field_documents !== "" && (
                    <span className="error error-msg">{error.field_documents}</span>
                  )}
                  <span>Please choose a package from the list below</span>
                  {jobData?.map((key) => {
                    return (
                      <>
                        <div className="radio_Group">
                          <label>
                            <input
                              className="radio"
                              type="radio"
                              id="siteJob"
                              name="field_documents"
                              value={key.nid}
                              checked={checked === key.nid ? true : false}
                              onChange={handleCheck}
                            />
                            {key.title}
                          </label>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="form-group">
                  <h3 className="legend-title mt30">Jobsite Address</h3>
                  <span>
                    Please enter the street address including specific building
                    details for the jobsite using the fields below.
                  </span>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div
                        className={classNames(
                          "form-item",
                          error.address_line1 !== "" && error.address_line1 && "error"
                        )}
                      >
                        <label>
                          Address{" "}
                          <span
                            className="form-required"
                            title="This field is required."
                          > *
                          </span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="address_line1"
                          placeholder=""
                          defaultValue={data?.address_line1}
                          onChange={handleChange}
                        />
                        {error.address_line1 !== "" && (
                          <span className="error error-msg">{error.address_line1}</span>
                        )}
                      </div>
                      <div className="form-item">
                        <label>Address 2</label>
                        <input
                          className="form-control"
                          type="text"
                          name="address_line2"
                          placeholder=""
                          defaultValue={data?.address_line2}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        className={classNames(
                          "form-item",
                          error.locality !== "" && error.locality && "error"
                        )}
                      >
                        <label>
                          City{" "}
                          <span
                            className="form-required"
                            title="This field is required."
                          > *
                          </span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="locality"
                          placeholder=""
                          defaultValue={data?.locality}
                          onChange={handleChange}
                        />
                        {error.locality !== "" && (
                          <span className="error error-msg">{error.locality}</span>
                        )}
                      </div>
                      <div
                        className={classNames(
                          "form-item",
                          error.administrative_area !== "" && error.administrative_area && "error"
                        )}
                      >
                        <label>
                          State/Province{" "}
                          <span
                            className="form-required"
                            title="This field is required."
                          > *
                          </span>
                        </label>
                        <SelectState
                          val={data.administrative_area}
                          name="administrative_area"
                          method={SetData}
                          data1={data}
                        />
                        {error.administrative_area !== "" && (
                          <span className="error error-msg">{error.administrative_area}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={12} md={6}>
                      <div
                        className={classNames(
                          "form-item",
                          error.postal_code !== "" && error.postal_code && "error"
                        )}
                      >
                        <label>
                          Zip Code{" "}
                          <span
                            className="form-required"
                            title="This field is required."
                          > *
                          </span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="postal_code"
                          placeholder=""
                          defaultValue={data?.postal_code}
                          onChange={handleChange}
                        />
                        {error.postal_code !== "" && (
                          <span className="error error-msg">{error.postal_code}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <button className="btn btn-danger btn-md" onClick={handleClick}>
                  Next
                </button>
              </form>
            </div>

          </Content>
        </Layout>
      </DefaultLayout>
    </>
  );
};

export default PackageForm;
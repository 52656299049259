import React from 'react';
import styled from "styled-components";

const AppContainer = styled.div`
  background: linear-gradient(45deg, red, blue);
  color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = () => {
  return (
    <AppContainer>
      <div className="txt-center">Please wait for a few minutes. We are generating your PDF</div>
    </AppContainer>
  )
}
export default Loading
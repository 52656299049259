import {
  // faList,
  // faStore,
  // faUserPlus,
  faFileSignature,
  // faEdit,
  // faCopy,
  // faSlidersH,
  // faHatChef,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/pro-light-svg-icons';

export const mainLinks = [
  {
    key: 'my-packages',
    text: 'My Packages',
    to: '/my-packages',
    auth: true,
    icon: faFileSignature,
  }
];

export const adminLinksList = [
  // {
  //   key: 'settings',
  //   text: 'Settings',
  //   to: '/settings',
  //   icon: faSlidersH,
  // },
  // {
  //   key: 'customize-workspace',
  //   text: 'Customize Workspace',
  //   to: '/company',
  //   icon: faEdit,
  // },
  // {
  //   key: 'menu',
  //   text: 'Manage Menu',
  //   to: '/manage-menu',
  //   icon: faList,
  // },
  // {
  //   key: 'modifiers',
  //   text: 'Manage Menu Modifiers',
  //   to: '/manage-menu-modifiers',
  //   icon: faHatChef,
  // },
  // {
  //   key: 'members',
  //   text: 'Manage Members',
  //   to: '/members',
  //   icon: faStore,
  // },
  // {
  //   key: 'invitations',
  //   text: 'Invitations',
  //   to: '/members/invitations/add',
  //   icon: faUserPlus,
  // },
  // {
  //   key: 'my-packages',
  //   text: 'My Packages',
  //   to: '/my-packages',
  //   icon: faFileSignature,
  // }
  // {
  //   key: 'contacts',
  //   text: 'Contacts',
  //   to: '',
  //   icon: faAddressCard,
  // },
  // {
  //   key: 'get-paid',
  //   text: 'Get Paid',
  //   to: '/',
  //   icon: faMoneyCheckAlt,
  // },
];


export const otherLinks = [
  // {
  //   key: 'tour',
  //   text: 'Tour',
  //   to: '/',
  //   icon: '',
  // },
  // {
  //   key: 'brand-guidelines',
  //   text: 'Brand Guidelines',
  //   to: '/',
  //   icon: '',
  // },
  {
    key: 'about',
    text: 'What is SBCAdocs?',
    to: '/about',
    auth: false,
    icon: '',
  },
  {
    key: 'pricing',
    text: 'Pricing',
    to: '/pricing',
    auth: false,
    icon: '',
  },
  {
    key: 'how-to',
    text: 'Help',
    to: '/how-to',
    auth: false,
    icon: '',
  },
  {
    key: 'contact',
    text: 'Contact',
    to: '/contact',
    auth: false,
    icon: '',
  },
  {
    key: 'legal',
    text: 'Legal',
    to: '/legal',
    auth: false,
    icon: '',
  },
  // {
  //   key: 'our-blog',
  //   text: 'Our Blog',
  //   to: '/',
  //   icon: '',
  // },
  {
    key: 'sign-in',
    text: `Sign In`,
    to: '/user/new-login',
    auth: false,
    icon: faSignInAlt,
  },
  {
    key: 'sign-out',
    text: `Sign Out`,
    to: '/user/logout',
    auth: true,
    icon: faSignOutAlt,
  },
];
